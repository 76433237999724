@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  width: 100%;
}

body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #000;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #f4d300;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #f4d300;
  cursor: pointer;
}

.custom-scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.custom-scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media (max-width: 1024px) {
  .custom-scrollbar {
    scrollbar-width: none !important; /* Firefox */
    -ms-overflow-style: none !important; /* IE and Edge */
  }

  .custom-scrollbar::-webkit-scrollbar,
  .custom-scrollbar::-webkit-scrollbar-track,
  .custom-scrollbar::-webkit-scrollbar-thumb {
    width: 0 !important;
    height: 0 !important;
    display: none !important;
    background: transparent !important;
  }
}
